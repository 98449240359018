import styled from 'styled-components'

export const CardTitleContainer = styled.div`
  margin-bottom: ${props => props.theme.spacings.xlarge};
`

export const InlineTextButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.primary.base};
  display: inline-block;
  font-family: ${props => props.theme.fontFamilies};
  margin: 0 auto;
  outline: none;
  text-decoration: underline;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`

export const SignupButtons = styled.div`
  display: flex;

  & > * {
    width: 50%;
  }

  & > *:not(:last-child) {
    margin-right: ${props => props.theme.spacings.xlarge};
  }
`
